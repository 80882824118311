import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { numberWithMK } from "../libs/utils";

const useStyles = makeStyles(theme => ({
  chartContainer: {
    height: "calc(100% - 70px)",
    borderLeft: "5px solid lightgray",
    borderBottom: "5px solid lightgray"
  },
  detailBox: {
    height: "100%"
  },
  totalBox: {
    height: "100%",
    backgroundColor: "#1276ff",
    float: "right"
  },
  leftWhiteBox: {
    height: "100%",
    backgroundColor: "white",
    display: "inline-block",
    float: "left"
  },
  rightWhiteBox: {
    height: "100%",
    backgroundColor: "white",
    display: "inline-block",
    float: "right"
  },
  centerBox: {
    height: "100%",
    display: "inline-block",
    float: "left"
  },
  para1: {
    position: "relative",
    color: "white",
    textAlign: "center",
    top: "calc(50% - 12px)",
    fontWeight: "bold"
  },
  para2: {
    position: "relative",
    color: "white",
    textAlign: "center",
    top: "calc(50% - 40px)",
    fontWeight: "bold",
    fontSize: "1.5rem",
    lineHeight: 1.2
  },
  chartLabel: {
    display: "inline-block",
    marginTop: 10,
    padding: "0 3px",
    verticalAlign: "top"
  }
}));

export default function ChartEstimatedBenefit(props) {
  const classes = useStyles();

  const benefitData = {
    incentifyDiscoveryImprovement: props.calcData.totalIncentifyDiscoveryImprovementAmt,
    reduceNoncompliance: props.calcData.reduceNoncomplianceAmt,
    maximizeMonetization: props.calcData.maximizeMonetizationAmt,
    increaseInternalEfficiencies: props.calcData.increaseInternalEfficienciesAmt
  };

  var totalBenefit = props.calcData.totalGrossBenefit;
  var activeBenefitItems = 0;
  var benefitStep = {
    incentifyDiscoveryImprovement: 0,
    reduceNoncompliance: 0,
    maximizeMonetization: 0,
    increaseInternalEfficiencies: 0
  };

  activeBenefitItems += props.client.bIncentifyDiscoveryImprovement ? 1 : 0;
  benefitStep["incentifyDiscoveryImprovement"] = activeBenefitItems;
  activeBenefitItems += props.client.bReduceNoncompliance ? 1 : 0;
  benefitStep["reduceNoncompliance"] = activeBenefitItems;
  activeBenefitItems += props.client.bMaximizeMonetization ? 1 : 0;
  benefitStep["maximizeMonetization"] = activeBenefitItems;
  activeBenefitItems += props.client.bIncreaseInternalEfficiencies ? 1 : 0;
  benefitStep["increaseInternalEfficiencies"] = activeBenefitItems;

  var benefitWidth = 100 / activeBenefitItems;
  var totalBoxWidth = 100 / (activeBenefitItems + 1);
  var hIncentifyDiscoveryImprovement = (benefitData["incentifyDiscoveryImprovement"] / totalBenefit) * 100;
  var hReduceNoncompliance = (benefitData["reduceNoncompliance"] / totalBenefit) * 100;
  var hMaximizeMonetization = (benefitData["maximizeMonetization"] / totalBenefit) * 100;
  var hIncreaseInternalEfficiencies = (benefitData["increaseInternalEfficiencies"] / totalBenefit) * 100;

  return (
    <Box className={classes.chartContainer}>
      <Box className={classes.detailBox} display="inline-block" style={{ width: 100 - totalBoxWidth + "%" }}>
        {props.client.bIncreaseInternalEfficiencies && benefitData["increaseInternalEfficiencies"] > 0 && (
          <Box style={{ height: hIncreaseInternalEfficiencies + "%", backgroundColor: "#757575" }}>
            <Box
              className={classes.leftWhiteBox}
              style={{ width: benefitWidth * (benefitStep["increaseInternalEfficiencies"] - 1) + "%" }}
            ></Box>
            <Box className={classes.centerBox} style={{ width: benefitWidth + "%" }}>
              <Typography component="p" className={classes.para1}>
                {"$" + numberWithMK(benefitData["increaseInternalEfficiencies"])}
              </Typography>
            </Box>
            <Box
              className={classes.rightWhiteBox}
              style={{
                width: benefitWidth * (activeBenefitItems - benefitStep["increaseInternalEfficiencies"]) + "%",
                borderBottom: "2px dotted lightgray"
              }}
            ></Box>
          </Box>
        )}
        {props.client.bMaximizeMonetization && benefitData["maximizeMonetization"] > 0 && (
          <Box style={{ height: hMaximizeMonetization + "%", backgroundColor: "#616161" }}>
            <Box className={classes.leftWhiteBox} style={{ width: benefitWidth * (benefitStep["maximizeMonetization"] - 1) + "%" }}></Box>
            <Box className={classes.centerBox} style={{ width: benefitWidth + "%" }}>
              <Typography component="p" className={classes.para1}>
                {"$" + numberWithMK(benefitData["maximizeMonetization"])}
              </Typography>
            </Box>
            <Box
              className={classes.rightWhiteBox}
              style={{
                width: benefitWidth * (activeBenefitItems - benefitStep["maximizeMonetization"]) + "%",
                borderBottom: "2px dotted lightgray"
              }}
            ></Box>
          </Box>
        )}
        {props.client.bReduceNoncompliance && benefitData["reduceNoncompliance"] > 0 && (
          <Box style={{ height: hReduceNoncompliance + "%", backgroundColor: "#424242" }}>
            <Box
              className={classes.leftWhiteBox}
              style={{ width: benefitWidth * (benefitStep["reduceNoncompliance"] - 1) + "%" }}
            ></Box>
            <Box className={classes.centerBox} style={{ width: benefitWidth + "%" }}>
              <Typography component="p" className={classes.para1}>
                {"$" + numberWithMK(benefitData["reduceNoncompliance"])}
              </Typography>
            </Box>
            <Box
              className={classes.rightWhiteBox}
              style={{
                width: benefitWidth * (activeBenefitItems - benefitStep["reduceNoncompliance"]) + "%",
                borderBottom: "2px dotted lightgray"
              }}
            ></Box>
          </Box>
        )}
        {props.client.bIncentifyDiscoveryImprovement && benefitData["incentifyDiscoveryImprovement"] > 0 && (
          <Box style={{ height: hIncentifyDiscoveryImprovement + "%", backgroundColor: "#000000" }}>
            <Box className={classes.leftWhiteBox} style={{ width: benefitWidth * (benefitStep["incentifyDiscoveryImprovement"] - 1) + "%" }}></Box>
            <Box className={classes.centerBox} style={{ width: benefitWidth + "%" }}>
              <Typography component="p" className={classes.para1}>
                {"$" + numberWithMK(benefitData["incentifyDiscoveryImprovement"])}
              </Typography>
            </Box>
            <Box
              className={classes.rightWhiteBox}
              style={{ width: benefitWidth * (activeBenefitItems - benefitStep["incentifyDiscoveryImprovement"]) + "%" }}
            ></Box>
          </Box>
        )}
      </Box>
      {totalBenefit > 0 && (
        <Box className={classes.totalBox} display="inline-block" style={{ width: totalBoxWidth + "%" }}>
          <Typography component="p" className={classes.para2}>
            {"$" + numberWithMK(totalBenefit)}
            <br />
            TOTAL
            <br />
            BENEFIT
          </Typography>
        </Box>
      )}
      <Box>
        {props.client.bIncentifyDiscoveryImprovement && (
          <Typography variant="body2" align="center" className={classes.chartLabel} style={{ width: totalBoxWidth + "%" }}>
            Incentify Discovery Improvement
          </Typography>
        )}
        {props.client.bReduceNoncompliance && (
          <Typography variant="body2" align="center" className={classes.chartLabel} style={{ width: totalBoxWidth + "%" }}>
            Reduce Noncompliance<br/>(claw back and recapture)
          </Typography>
        )}
        {props.client.bMaximizeMonetization && (
          <Typography variant="body2" align="center" className={classes.chartLabel} style={{ width: totalBoxWidth + "%" }}>
            Maximize Monetization<br/>(value and timing)
          </Typography>
        )}
        {props.client.bIncreaseInternalEfficiencies && (
          <Typography variant="body2" align="center" className={classes.chartLabel} style={{ width: totalBoxWidth + "%" }}>
            Increase Internal Efficiencies
          </Typography>
        )}
      </Box>
    </Box>
  );
}
