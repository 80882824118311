import React, { useState, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = {
  input: {
    display: "none"
  }
};

function ImagePicker(props) {
  const logoFile = useRef(null);
  const MAX_LOGO_SIZE = 2000000;
  const [previewImgSrc, setPreviewImgSrc] = useState(props.previewImage);

  function handlelogoFileChange(event) {
    logoFile.current = event.target.files[0];

    if (!logoFile.current) return;

    if (logoFile.current.size > MAX_LOGO_SIZE) {
      alert(`Please pick a file smaller than ${MAX_LOGO_SIZE / 1000000} MB.`);
      return;
    }

    var reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImgSrc(reader.result);
    };

    reader.readAsDataURL(logoFile.current);

    props.setImageFile(logoFile.current);
  }

  const { classes } = props;

  return (
    <React.Fragment>
      <img src={previewImgSrc} className="logoImgPreview" />
      <input
        accept="image/*"
        className={classes.input}
        id="logoFile"
        onChange={handlelogoFileChange}
        type="file"
      />
      <label htmlFor="logoFile">
        <Button
          variant="outlined"
          component="span"
          className="textTransformNone"
        >
          Choose Logo
        </Button>
      </label>
    </React.Fragment>
  );
}

export default withStyles(styles)(ImagePicker);
