import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
  pageHeader: {
    display: "block",
    padding: 20,
    backgroundColor: "#1A4FE5"
  },
  clientLogo: {
    height: 80,
    backgroundColor: 'white',
  },
  rightBox: {
    position: "absolute",
    top: 15,
    right: 20
  },
  mainLogo: {
    width: 160
  },
  title: {
    display: "inline",
    position: "relative",
    top: -34,
    marginLeft: 20
  }
}));

export default function PageHeader(props) {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.pageHeader}>
      {props.client.logoImgFullPath && <img src={props.client.logoImgFullPath} className={classes.clientLogo} alt="Logo" />}
      <Typography variant="h4" className={classes.title}>
        Incentify ROI Calculator for {props.client.name}
      </Typography>
      <Box className={classes.rightBox} align="right">
        <img
          src="https://oixstatic.s3.amazonaws.com/logos/incentify-logo-white-temporary.png"
          className={classes.mainLogo}
          alt="Incentify Logo"
        />
        <Typography variant="body2" gutterBottom>
          {props.client.contactName}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {props.client.contactEmail}
        </Typography>
      </Box>
    </AppBar>
  );
}
