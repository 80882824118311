import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numberWithCommas, numberWithMK } from "../libs/utils";

require("highcharts/modules/annotations")(Highcharts);

export default function ChartEcoBenefit(props) {
  const benefitData = [];

  for (var i = 0; i < 48; i++) {
    benefitData.push([i + 1, props.calcData.netCumulativeEconomicBenefit[i]]);
  }

  const options = {
    chart: {
      type: "area"
    },
    title: {
      text: ""
    },
    xAxis: {
      title: {
        text: "Months"
      },
      tickInterval: 1
    },
    yAxis: {
      startOnTick: true,
      endOnTick: true,
      title: {
        text: null
      },
      labels: {
        formatter: function() {
          return numberWithMK(this.value);
        }
      }
    },
    credits: {
      enabled: false
    },
    annotations: [
      {
        labelOptions: {
          backgroundColor: "rgba(255,255,255,1)",
          verticalAlign: "top",
          x: 2,
          y: 13
        },
        labels: [
          {
            point: {
              xAxis: 0,
              yAxis: 0,
              x: props.calcData.projectBreakevenMonth,
              y: props.calcData.netCumulativeEconomicBenefit[props.calcData.projectBreakevenMonth - 1]
            },
            text: "Breakeven"
          },
          {
            point: {
              xAxis: 0,
              yAxis: 0,
              x: 12,
              y: props.calcData.netCumulativeEconomicBenefit[11]
            },
            text: "Year 1"
          },
          {
            point: {
              xAxis: 0,
              yAxis: 0,
              x: 24,
              y: props.calcData.netCumulativeEconomicBenefit[23]
            },
            text: "Year 2"
          },
          {
            point: {
              xAxis: 0,
              yAxis: 0,
              x: 36,
              y: props.calcData.netCumulativeEconomicBenefit[35]
            },
            text: "Year 3"
          },
          {
            point: {
              xAxis: 0,
              yAxis: 0,
              x: 48,
              y: props.calcData.netCumulativeEconomicBenefit[47]
            },
            text: "Year 4"
          }
        ]
      }
    ],
    tooltip: {
      formatter: function() {
        return "Month " + this.point.x + "<br>" + numberWithCommas(this.point.y);
      }
    },
    series: [
      {
        name: "Net Benefit",
        data: benefitData,
        showInLegend: false,
        color: "rgba(19, 117, 255, 1)"
      }
    ]
  };

  return (
    <React.Fragment>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </React.Fragment>
  );
}
