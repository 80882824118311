import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";
import { numberWithCommas } from "../libs/utils";

const useStyles = makeStyles(({
  subtitle: {
    marginLeft: 16
  },
  leftCol: {
    paddingRight: 0
  },
  iconButton: {
    padding: '0 0 0 5px',
    top: -2
  }
}));

export default function BenefitAssumptions(props) {
  const classes = useStyles();

  // Generate table row
  function createData(id, item, amount) {
    return { id, item, amount };
  }

  const rows = [];
  if (props.client.bIncentifyDiscoveryImprovement) {
    rows.push(createData(0, "Incentify Discovery Improvement %", props.calcData.incentifyDiscoveryImprovement + "%"));
    rows.push(createData(1, "Incentify Discovery Improvement $",  "$" + numberWithCommas(props.calcData.incentifyDiscoveryImprovementAmt)));
  }
  if (props.client.bReduceNoncompliance) {
    rows.push(createData(2, "Reduce Noncompliance(claw back and recapture)", props.calcData.reduceNoncompliance + "%"));
  }
  if (props.client.bMaximizeMonetization) {
    rows.push(createData(3, "Maximize Monetization(value and timing)", props.calcData.maximizeMonetization + "%"));
  }
  if (props.client.bIncreaseInternalEfficiencies) {
    rows.push(createData(4, "Increase Internal Efficiencies", props.calcData.increaseInternalEfficiencies + "%"));
  }

  return (
    <React.Fragment>
      <Typography variant="subtitle1" gutterBottom className={classes.subtitle}>
        Benefit Assumptions
      </Typography>
      <Table size="small">
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell className={classes.leftCol}>
                {row.item}
                {row.id === 1 && (
                  <Tooltip
                    title='Incentify Discovery Improvement $ is the product of Incentify Discovery Improvement % and the Credit & Incentive Discovery.'
                    placement='top'
                    arrow
                  >
                    <IconButton disableRipple={true} disableFocusRipple={true} className={classes.iconButton}>
                      <HelpIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>                  
                )}
              </TableCell>
              <TableCell align="right">{row.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
