import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddRoundedIcon from "@material-ui/icons/GroupAddRounded";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AddClient from "./addClient";
import AdjustAssumptions from "./adjustAssumptions";
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles(theme => ({
  appBar: {
    height: "100%",
    width: 60,
    left: 0,
    backgroundColor: "#000000",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  toolbar: {
    flexDirection: "column",
    padding: "20px 0",
    height: "100%",
    textAlign: "center"
  },
  logo: {
    position: "relative",
    width: 50,
    marginBottom: 20,
    objectFit: "contain"
  },
  title: {
    flexGrow: 1
  },
  adjustTipBox: {
    position: "absolute",
    width: 180,
    left: 56,
    top: 90,
    padding: 10,
    textAlign: "left",
    backgroundColor: "#7AC843",
    color: "white",
    opacity: 0,
    transition: "all ease .5s"
  },
  adjustTipArrow: {
    position: "absolute",
    display: "inline-block",
    top: 20,
    left: -10,
    borderColor: "transparent #7AC843",
    borderStyle: "solid",
    borderWidth: "10px 10px 10px 0px",
    height: 0,
    width: 0
  },
  adjustTipButton: {
    color: "#7AC843",
    padding: 2
  }
}));

export default function AppNavBar(props) {
  const classes = useStyles();
  const [adjustTipStatus, setAdjustTipStatus] = useState(0);
  const [modalStatus, setModalStatus] = useState(false);

  var tipAccepted = localStorage.getItem("tipAccepted");

  if (adjustTipStatus === 0 && !tipAccepted) {
    setTimeout(function() {
      setAdjustTipStatus(1);
    }, 3000);
  }

  var tipBoxOpacity = adjustTipStatus === 1 ? 1 : 0;

  var protocol = window.location.protocol;
  var host = window.location.host;

  return (
    <React.Fragment>
      <AppBar position="fixed" className={clsx(classes.appBar)}>
        <Toolbar className={classes.toolbar}>
          <img src="/incentify-logo.png" className={classes.logo} alt="Incentify Logo" />
          {props.pageType === "report" && (
            <React.Fragment>
              <Tooltip title="Adjust Report Assumptions" placement="right" arrow>
                <IconButton color="inherit" onClick={() => props.setAdjustmentBoxStatus(true)}>
                  <SettingsIcon fontSize="large" />
                </IconButton>
              </Tooltip>
              <Box className={classes.adjustTipBox} style={{ opacity: tipBoxOpacity }}>
                <Box className={classes.adjustTipArrow}></Box>
                <Typography variant="body2" component="p" style={{ fontWeight: 700, marginBottom: 10 }}>
                  Welcome!
                </Typography>
                <Typography variant="body2" style={{ marginBottom: 10 }}>
                  Click here if you want to adjust this report's assumptions.
                </Typography>
                <Button
                  variant="contained"
                  className={classes.adjustTipButton}
                  onClick={() => {
                    localStorage.setItem("tipAccepted", true);
                    setAdjustTipStatus(-1);
                  }}
                >
                  Ok
                </Button>
              </Box>
              <Tooltip title="Share With Your Team" placement="right" arrow>
                <IconButton
                  color="inherit"
                  onClick={() => setModalStatus(true)}
                >
                  <GroupAddRoundedIcon fontSize="large" />
                </IconButton>
              </Tooltip>
              <Dialog
                open={modalStatus}
                onClose={() => setModalStatus(false)}
                aria-labelledby="form-dialog-title"
              >
                <DialogContent id="form-dialog-title">
                  <Typography variant="body2" style={{ marginBottom: 10, fontSize: '16px', fontWeight: 'bold' }}>
                    Share This ROI Report with Colleagues
                  </Typography>
                  <Typography variant="body2" style={{ marginBottom: 20 }}>
                    Would you like to invite your colleagues to view and adjust this ROI Report? Please copy and send them the following web link and access password.
                  </Typography>
                  <Typography variant="body2" style={{ marginBottom: 5, fontWeight: 'bold' }}>
                    Web Link:
                  </Typography>
                  <Typography variant="body2" style={{ marginBottom: 10 }}>
                    {protocol}{"//"}{host}/{props.client.url}
                  </Typography>
                  <Typography variant="body2" style={{ marginBottom: 5, fontWeight: 'bold' }}>
                    Access Password:
                  </Typography>
                  <Typography variant="body2" style={{ marginBottom: 10 }}>
                    { props.client.password }
                  </Typography>
                </DialogContent>
                <DialogActions className="p15">
                  <Button
                    variant="contained"
                    onClick={() => setModalStatus(false)}
                    color="default"
                    className="textTransformNone"
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </React.Fragment>
          )}
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}></Typography>
          {props.pageType === "clients" && <AddClient onCreate={props.onCreateClient} />}
          <Tooltip title="Log Out" placement="right" arrow>
            <IconButton color="inherit" onClick={props.signOut}>
              <ExitToAppIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>

      {props.pageType === "report" && (
        <AdjustAssumptions
          adjustmentBoxStatus={props.adjustmentBoxStatus}
          setAdjustmentBoxStatus={props.setAdjustmentBoxStatus}
          client={props.client}
          updateClient={props.updateClient}
          updateCalcData={props.updateCalcData}
        />
      )}
    </React.Fragment>
  );
}
