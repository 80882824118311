import { Storage } from "aws-amplify";

export async function s3Upload(file, filename) {
  try {
    const stored = await Storage.put(filename, file, {
      contentType: file.type
    });
    return stored.key;
  } catch (err) {
    console.log(`Upload to S3 error: ${err}`);
  }
}

export async function s3Get(key) {
  try {
    const data = await Storage.get(key);
    return data;
  } catch (err) {
    console.log(`Get S3 object error: ${err}`);
  }
}

export function s3Delete(key) {
  try {
    Storage.remove(key);
  } catch (err) {
    console.log(`Delete S3 object error: ${err}`);
  }
}
