/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      name
      url
      password
      logoImg
      contactName
      contactEmail
      annualCapitalExpenditure
      capExFundedByCi
      currentCiPortfolio
      annualLicenseFee
      setupFee
      averageMonthsToMonetization
      averageMonthsToUploadCiPortfolio
      bIncentifyDiscoveryImprovement
      bReduceNoncompliance
      bMaximizeMonetization
      bIncreaseInternalEfficiencies
      incentifyDiscoveryImprovement
      reduceNoncompliance
      maximizeMonetization
      increaseInternalEfficiencies
      createdAt
      updatedAt
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      name
      url
      password
      logoImg
      contactName
      contactEmail
      annualCapitalExpenditure
      capExFundedByCi
      currentCiPortfolio
      annualLicenseFee
      setupFee
      averageMonthsToMonetization
      averageMonthsToUploadCiPortfolio
      bIncentifyDiscoveryImprovement
      bReduceNoncompliance
      bMaximizeMonetization
      bIncreaseInternalEfficiencies
      incentifyDiscoveryImprovement
      reduceNoncompliance
      maximizeMonetization
      increaseInternalEfficiencies
      createdAt
      updatedAt
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      name
      url
      password
      logoImg
      contactName
      contactEmail
      annualCapitalExpenditure
      capExFundedByCi
      currentCiPortfolio
      annualLicenseFee
      setupFee
      averageMonthsToMonetization
      averageMonthsToUploadCiPortfolio
      bIncentifyDiscoveryImprovement
      bReduceNoncompliance
      bMaximizeMonetization
      bIncreaseInternalEfficiencies
      incentifyDiscoveryImprovement
      reduceNoncompliance
      maximizeMonetization
      increaseInternalEfficiencies
      createdAt
      updatedAt
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      username
      password
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      username
      password
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      username
      password
      type
      createdAt
      updatedAt
    }
  }
`;
