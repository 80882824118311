import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import { s3Delete } from "../libs/awsS3Lib";
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(1),
    position: "relative"
  },
  buttonProgress: {
    color: green[800],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

export default function DeleteClient(props) {
  const [modalStatus, setModalStatus] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const classes = useStyles();

  async function handleDelete() {
    setLoading(true);

    var client = {
      id: props.currentClient.id
    };

    try {
      await API.graphql(
        graphqlOperation(mutations.deleteClient, { input: client })
      );
      props.onDelete(); // Decrease clientChanged state so that get the clients list again
    } catch (err) {
      console.log(`Error: ${err}`);
      setLoading(false);
    }

    // Delete logo image from S3
    s3Delete(props.currentClient.logoImg);

    // Delete auth
    var account = {
      username: props.currentClient.url
    };

    API.graphql(graphqlOperation(mutations.deleteAccount, { input: account }));

    setLoading(false);
    setModalStatus(false);
  }

  return (
    <div style={{ display: "inline-block" }}>
      <Tooltip title="Delete" placement="top" arrow>
        <IconButton
          color="secondary"
          aria-label="Delete"
          onClick={() => setModalStatus(true)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        open={modalStatus}
        onClose={() => setModalStatus(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent id="form-dialog-title">
          Are you sure you want to delete {props.currentClient.name}?
        </DialogContent>
        <DialogActions className="p15">
          <Button
            variant="contained"
            onClick={() => setModalStatus(false)}
            color="default"
            className="textTransformNone"
          >
            Cancel
          </Button>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              className="colorButton3 textTransformNone"
              disabled={loading}
              onClick={() => handleDelete()}
              style={{ width: 80 }}
            >
              Delete
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
