import React, { useState } from "react";
import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SettingsIcon from "@material-ui/icons/Settings";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Slider from "@material-ui/core/Slider";
import { numberWithCommas, numberWithoutCharacters } from "../libs/utils";

const theme = createMuiTheme({
  overrides:{
    MuiSlider: {
      thumb:{
        color: "#2F85F1",
      },
    }
  }
});

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(2)
  },
  textField: {
    width: 220
  },
  adjustmentBox: {
    position: "fixed",
    height: "100vh",
    width: 300,
    top: 0,
    left: -300,
    zIndex: 1101,
    backgroundColor: "#F1F1F1",
    padding: 20,
    transition: "all ease .3s"
  },
  adjustmentPanel: {
    backgroundColor: "white",
    padding: "10px 0 !important",
    margin: 10,
    maxHeight: "calc(100vh - 140px)",
    overflowY: "auto",
    overflowX: "hidden"
  },
  buttonBox: {
    float: "right",
    marginTop: 10
  },
  cancelButton: {
    marginRight: 15,
    textTransform: "none"
  },
  adjustButton: {
    backgroundColor: "#7AC843",
    color: "white",
    borderRadius: 15,
    textTransform: "none"
  },
  benefitSlider: {
    width: 220,
    margin: "50px 16px 16px 16px",
    display: "inline-block",
    textAlign: "center"
  },
  benefitCalcLabel : {
    fontSize: 14
  }
}));

const marks10 = [
  {
    value: 0,
    label: "0%"
  },
  {
    value: 10,
    label: "10%"
  }
];
const marks100 = [
  {
    value: 0,
    label: "0%"
  },
  {
    value: 100,
    label: "100%"
  }
];

const AdjustAssumptions = props => {
  const classes = useStyles();

  const [calcPrimaryFields, setCalcPrimaryFields] = useState({
    currentCiPortfolio: props.client.currentCiPortfolio,
    incentifyDiscoveryImprovement: props.client.incentifyDiscoveryImprovement,
    reduceNoncompliance: props.client.reduceNoncompliance,
    maximizeMonetization: props.client.maximizeMonetization,
    increaseInternalEfficiencies: props.client.increaseInternalEfficiencies
  });

  function updateCalcPrimaryFields(name, value) {
    var tmp = {
      ...calcPrimaryFields,
      [name]: value
    };

    setCalcPrimaryFields(tmp);
    props.updateCalcData(tmp);
  }

  function handleSave() {
    props.updateClient(calcPrimaryFields);
    props.setAdjustmentBoxStatus(false);
  }

  function handleRevert() {
    var tmp = {
      ...calcPrimaryFields,
      currentCiPortfolio: props.client.currentCiPortfolio,
      incentifyDiscoveryImprovement: props.client.incentifyDiscoveryImprovement,
      reduceNoncompliance: props.client.reduceNoncompliance,
      maximizeMonetization: props.client.maximizeMonetization,
      increaseInternalEfficiencies: props.client.increaseInternalEfficiencies
    };
    setCalcPrimaryFields(tmp);

    props.updateCalcData(tmp);

    props.setAdjustmentBoxStatus(false);
  }

  var boxLeft = props.adjustmentBoxStatus ? 0 : -300;

  return (
    <div className={classes.adjustmentBox} style={{ left: boxLeft }}>
      <Typography component="h4" variant="h6" color="inherit" noWrap gutterBottom display="inline">
        Adjust Report Assumptions
      </Typography>
      <Box display="inline" className={classes.buttonBox}>
        <Button className={classes.cancelButton} onClick={() => handleRevert()}>
          Revert
        </Button>
        <Button
          variant="contained"
          color="inherit"
          className={classes.adjustButton}
          endIcon={<SettingsIcon />}
          onClick={() => handleSave()}
        >
          Save
        </Button>
      </Box>
      <Grid container spacing={3}>
        <Grid container className={classes.adjustmentPanel}>
          <Grid item xs={12}>
            <FormControl className={classes.margin}>
              <InputLabel htmlFor="currentCiPortfolio">Current C&I Portfolio</InputLabel>
              <Input
                id="currentCiPortfolio"
                name="currentCiPortfolio"
                value={numberWithCommas(calcPrimaryFields.currentCiPortfolio)}
                onChange={e => {
                  e.persist();
                  updateCalcPrimaryFields("currentCiPortfolio", numberWithoutCharacters(e.target.value));
                }}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                className={classes.textField}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {props.client.bIncentifyDiscoveryImprovement && (
              <Box className={classes.benefitSlider}>
                <ThemeProvider theme={theme}>
                  <Slider
                    value={calcPrimaryFields.incentifyDiscoveryImprovement}
                    aria-labelledby="incentifyDiscoveryImprovement"
                    marks={marks100}
                    valueLabelDisplay="on"
                    min={0}
                    max={100}
                    step={0.01}
                    onChange={(e, value) => {
                      updateCalcPrimaryFields("incentifyDiscoveryImprovement", value);
                    }}
                  />
                </ThemeProvider>
                <InputLabel id="incentifyDiscoveryImprovement" className={classes.benefitCalcLabel}>Incentify Discovery Improvement (%)</InputLabel>
              </Box>
            )}
            {props.client.bReduceNoncompliance && (
              <Box className={classes.benefitSlider}>
                <ThemeProvider theme={theme}>
                  <Slider
                    value={calcPrimaryFields.reduceNoncompliance}
                    aria-labelledby="reduceNoncompliance"
                    marks={marks10}
                    valueLabelDisplay="on"
                    min={0}
                    max={10}
                    step={0.01}
                    onChange={(e, value) => {
                      updateCalcPrimaryFields("reduceNoncompliance", value);
                    }}
                  />
                </ThemeProvider>
                <InputLabel id="reduceNoncompliance" className={classes.benefitCalcLabel}>Reduce Noncompliance(claw back and recapture) (%)</InputLabel>
              </Box>
            )}
            {props.client.bMaximizeMonetization && (
              <Box className={classes.benefitSlider}>
                <ThemeProvider theme={theme}>
                  <Slider
                    value={calcPrimaryFields.maximizeMonetization}
                    aria-labelledby="maximizeMonetization"
                    marks={marks10}
                    valueLabelDisplay="on"
                    min={0}
                    max={10}
                    step={0.01}
                    onChange={(e, value) => {
                      updateCalcPrimaryFields("maximizeMonetization", value);
                    }}
                  />
                </ThemeProvider>
                <InputLabel id="maximizeMonetization" className={classes.benefitCalcLabel}>Maximize Monetization(value and timing) (%)</InputLabel>
              </Box>
            )}
            {props.client.bIncreaseInternalEfficiencies && (
              <Box className={classes.benefitSlider}>
                <ThemeProvider theme={theme}>
                  <Slider
                    value={calcPrimaryFields.increaseInternalEfficiencies}
                    aria-labelledby="increaseInternalEfficiencies"
                    marks={marks10}
                    valueLabelDisplay="on"
                    min={0}
                    max={10}
                    step={0.01}
                    onChange={(e, value) => {
                      updateCalcPrimaryFields("increaseInternalEfficiencies", value);
                    }}
                  />
                </ThemeProvider>
                <InputLabel id="increaseInternalEfficiencies" className={classes.benefitCalcLabel}>Increase Internal Efficiencies (%)</InputLabel>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const shouldComponentKeep = function(prevProps, nextProps) {
  // Update when Cancel/Revert is clicked
  if (prevProps.adjustmentBoxStatus !== nextProps.adjustmentBoxStatus) {
    return false;
  }
  return true;
};

export default React.memo(AdjustAssumptions, shouldComponentKeep);
