import React, { useState, useEffect } from "react";
import { Redirect } from "react-router";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import AppNavBar from "../components/appNavBar";
import ListClients from "../components/listClients";
import { s3Get } from "../libs/awsS3Lib";

export default function Home(props) {
  const [clients, setClients] = useState([]);
  const [clientChanged, setclientChanged] = useState(0);
  const sessUser = localStorage.getItem("user");

  useEffect(() => {
    getClients();
  }, [clientChanged]);

  async function getClients() {
    try {
      const data = await API.graphql(graphqlOperation(queries.listClients));

      // Get the logo image file from the S3
      data.data.listClients.items.forEach(async function(client) {
        client.logoImgFullPath = client.logoImg ? await s3Get(client.logoImg) : null;
      });

      setClients(data.data.listClients.items);
    } catch (err) {
      console.log(`Error: ${err}`);
    }
  }

  function addClient() {
    setclientChanged(clientChanged + 1);
  }

  function deleteClient() {
    setclientChanged(clientChanged - 1);
  }

  function updateClient(data) {
    setClients(clients.map(client => (client.id === data.id ? { ...client, ...data } : client)));
  }

  async function signOut() {
    localStorage.clear();
    props.userHasAuthenticated(false);
    props.history.push("/signin");
  }

  return (
    <div>
      {props.isAuthenticated && sessUser === "admin" ? (
        <div>
          <AppNavBar signOut={signOut} pageType="clients" onCreateClient={addClient} />
          <ListClients clients={clients} onUpdateClient={updateClient} onDeleteClient={deleteClient} />
        </div>
      ) : (
        <Redirect to="/signin" />
      )}
    </div>
  );
}
