export function numberWithCommas(number, decPlaces) {
  decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces;

  // Remove all characters except leading - sign, one dot for decimal
  number = number
    .toString()
    .replace(/[^0-9.-]/g, "")
    .replace(/(\..*)\./g, "$1")
    .replace(/(?!^)-/g, "");

  var parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (typeof parts[1] !== "undefined") {
    parts[1] = parts[1].substring(0, decPlaces);
  }

  return parts.join(".");
}

export function numberWithoutCharacters(number) {
  return number
    .toString()
    .replace(/[^0-9.-]/g, "")
    .replace(/(\..*)\./g, "$1")
    .replace(/(?!^)-/g, "");
}

export function numberWithMK(num) {
  if (Math.abs(num) > 999999) {
    return numberWithCommas(Math.sign(num) * (Math.abs(num) / 1000000).toFixed(2)) + "MM";
  } else if (Math.abs(num) > 999) {
    return numberWithCommas(Math.sign(num) * (Math.abs(num) / 1000).toFixed(2)) + "K";
  } else {
    return Math.sign(num) * Math.abs(num);
  }
}
