/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://uz74afja4zgd7lfpcww3aikzom.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2qu6okuqdfen5aylgdibfdkog4",
    "aws_cognito_identity_pool_id": "us-east-1:b1fb98a1-134f-4ebb-918a-7d418672adb3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_FSG8bUPEt",
    "aws_user_pools_web_client_id": "3t43p4d0etb5n54dd89gltaom0",
    "oauth": {},
    "aws_user_files_s3_bucket": "roiapp-assets223636-prodtwo",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
