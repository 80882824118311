import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import EditClient from "./editClient";
import DeleteClient from "./deleteClient";
import Tooltip from '@material-ui/core/Tooltip';

const styles = {
  table: {
    maxWidth: 850,
    marginTop: 20
  },
  tableHeadText: {
    fontWeight: "bold"
  },
  td: {
    padding: "3px 16px"
  },
  navLink: {
    textDecoration: "none"
  }
};

export default function ListClients(props) {
  return (
    <TableContainer align="center">
      <Table style={styles.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={styles.tableHeadText}>#</TableCell>
            <TableCell style={styles.tableHeadText}>Name</TableCell>
            <TableCell style={styles.tableHeadText}>Username / URL</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.clients.map((client, index) => (
            <TableRow key={client.url}>
              <TableCell component="th" scope="row" style={styles.td}>
                {index + 1}
              </TableCell>
              <TableCell style={styles.td}>{client.name}</TableCell>
              <TableCell style={styles.td}>{client.url}</TableCell>
              <TableCell style={styles.td} align="right">
                <Tooltip title="Go to ROI Report" placement="top" arrow>
                    <a href={`/${client.url}`} style={styles.navLink} target="_blank" rel="noopener noreferrer">
                      <Button variant="contained" className="colorButton1" size="small" startIcon={<ExitToAppIcon />}>
                        ROI
                      </Button>
                    </a>
                </Tooltip>
                <EditClient currentClient={client} onUpdate={props.onUpdateClient} />
                <DeleteClient currentClient={client} onDelete={props.onDeleteClient} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
