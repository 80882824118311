import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { numberWithCommas } from "../libs/utils";

const useStyles = makeStyles(theme => ({
  subtitle: {
    marginLeft: 16
  }
}));

const Roi = props => {
  const classes = useStyles();

  // Generate table row
  function createData(id, item, amount) {
    return { id, item, amount };
  }

  const rows = [
    createData(0, "First Year ROI", numberWithCommas(props.calcData.firstYearRoi) + "%"),
    createData(1, "Second Year ROI", numberWithCommas(props.calcData.secondYearRoi) + "%"),
    createData(2, "Third Year ROI", numberWithCommas(props.calcData.thirdYearRoi) + "%"),
    createData(3, "Fourth Year ROI", numberWithCommas(props.calcData.fourthYearRoi) + "%"),
    createData(4, "Two Year ROI", numberWithCommas(props.calcData.twoYearRoi) + "%"),
    createData(5, "Four Year ROI", numberWithCommas(props.calcData.fourYearRoi) + "%"),
    createData(6, "2 Year Net Cumulative Economic Benefit", "$" + numberWithCommas(props.calcData.twoYearBenefitTotalAmt)),
    createData(7, "4 Year Net Cumulative Economic Benefit", "$" + numberWithCommas(props.calcData.fourYearBenefitTotalAmt)),
    createData(8, "Project Breakeven (Month)", props.calcData.projectBreakevenMonth)
  ];

  return (
    <React.Fragment>
      <Typography variant="subtitle1" gutterBottom className={classes.subtitle}>
        ROI
      </Typography>
      <Table size="small">
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.item}</TableCell>
              <TableCell align="right">{row.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

const shouldComponentKeep = function(prevProps, nextProps) {
  return (
    prevProps.calcData.firstYearRoi === nextProps.calcData.firstYearRoi &&
    prevProps.calcData.secondYearRoi === nextProps.calcData.secondYearRoi &&
    prevProps.calcData.projectBreakevenMonth === nextProps.calcData.projectBreakevenMonth &&
    prevProps.calcData.twoYearBenefitTotalAmt === nextProps.calcData.twoYearBenefitTotalAmt
  );
};

export default React.memo(Roi, shouldComponentKeep);
