import React, { useState, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import bcrypt from "bcryptjs";
import ImagePicker from "./imagePicker";
import { s3Upload, s3Delete } from "../libs/awsS3Lib";
import { numberWithCommas, numberWithoutCharacters } from "../libs/utils";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[800],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const initialClientDetails = {
  clientName: "",
  clientUrl: "",
  clientPassword: "",
  contactName: "",
  contactEmail: "",
  annualCapitalExpenditure: "",
  capExFundedByCi: "",
  currentCiPortfolio: "",
  annualLicenseFee: "",
  setupFee: "",
  averageMonthsToMonetization: "",
  averageMonthsToUploadCiPortfolio: "",
  bIncentifyDiscoveryImprovement: true,
  bReduceNoncompliance: true,
  bMaximizeMonetization: true,
  bIncreaseInternalEfficiencies: true,
};

function reducer(state, action) {
  switch (action.type) {
    case "updateClientDetails":
      return {
        ...state,
        [action.e.target.name]: action.e.target.value,
      };
    case "updateBenefitConfig":
      return {
        ...state,
        [action.e.target.name]: action.e.target.checked,
      };
    case "initClientDetails":
      return {
        ...state,
        [action.name]: action.value,
      };
    default:
      return state;
  }
}

export default function EditClient(props) {
  const [modalStatus, setModalStatus] = useState(false);
  const [clientDetails, updateClientDetails] = useReducer(reducer, initialClientDetails);
  const [newLogoFile, setNewLogoFile] = useState(null);
  const [loading, setLoading] = React.useState(false);

  const classes = useStyles();

  function openEditModal() {
    setModalStatus(true);

    // Set field values switch as current client
    updateClientDetails({
      type: "initClientDetails",
      name: "annualCapitalExpenditure",
      value: props.currentClient.annualCapitalExpenditure,
    });
    updateClientDetails({
      type: "initClientDetails",
      name: "capExFundedByCi",
      value: props.currentClient.capExFundedByCi,
    });
    updateClientDetails({
      type: "initClientDetails",
      name: "currentCiPortfolio",
      value: props.currentClient.currentCiPortfolio,
    });
    updateClientDetails({
      type: "initClientDetails",
      name: "annualLicenseFee",
      value: props.currentClient.annualLicenseFee,
    });
    updateClientDetails({
      type: "initClientDetails",
      name: "setupFee",
      value: props.currentClient.setupFee,
    });
    updateClientDetails({
      type: "initClientDetails",
      name: "averageMonthsToMonetization",
      value: props.currentClient.averageMonthsToMonetization,
    });
    updateClientDetails({
      type: "initClientDetails",
      name: "averageMonthsToUploadCiPortfolio",
      value: props.currentClient.averageMonthsToUploadCiPortfolio,
    });

    updateClientDetails({
      type: "initClientDetails",
      name: "bIncentifyDiscoveryImprovement",
      value: props.currentClient.bIncentifyDiscoveryImprovement,
    });
    updateClientDetails({
      type: "initClientDetails",
      name: "bReduceNoncompliance",
      value: props.currentClient.bReduceNoncompliance,
    });
    updateClientDetails({
      type: "initClientDetails",
      name: "bMaximizeMonetization",
      value: props.currentClient.bMaximizeMonetization,
    });
    updateClientDetails({
      type: "initClientDetails",
      name: "bIncreaseInternalEfficiencies",
      value: props.currentClient.bIncreaseInternalEfficiencies,
    });
  }

  async function handleSubmit() {
    setLoading(true);

    var clientUrl = clientDetails.clientUrl || props.currentClient.url;

    var storedLogo = props.currentClient.logoImg;
    if (newLogoFile) {
      const filename = `${clientUrl}_${Date.now()}_${newLogoFile.name}`;
      storedLogo = await s3Upload(newLogoFile, filename);

      s3Delete(props.currentClient.logoImg);
    }

    var client = {
      id: props.currentClient.id,
      name: clientDetails.clientName || props.currentClient.name,
      url: clientUrl,
      password: clientDetails.clientPassword || props.currentClient.password,
      logoImg: storedLogo,
      contactName: clientDetails.contactName,
      contactEmail: clientDetails.contactEmail,
      annualCapitalExpenditure: clientDetails.annualCapitalExpenditure || props.currentClient.annualCapitalExpenditure,
      capExFundedByCi: clientDetails.capExFundedByCi || props.currentClient.capExFundedByCi,
      currentCiPortfolio: clientDetails.currentCiPortfolio || props.currentClient.currentCiPortfolio,
      annualLicenseFee: clientDetails.annualLicenseFee || props.currentClient.annualLicenseFee,
      setupFee: clientDetails.setupFee || props.currentClient.setupFee,
      averageMonthsToMonetization: clientDetails.averageMonthsToMonetization || props.currentClient.averageMonthsToMonetization,
      averageMonthsToUploadCiPortfolio: clientDetails.averageMonthsToUploadCiPortfolio || props.currentClient.averageMonthsToUploadCiPortfolio,
      bIncentifyDiscoveryImprovement: clientDetails.bIncentifyDiscoveryImprovement,
      bReduceNoncompliance: clientDetails.bReduceNoncompliance,
      bMaximizeMonetization: clientDetails.bMaximizeMonetization,
      bIncreaseInternalEfficiencies: clientDetails.bIncreaseInternalEfficiencies,
    };

    API.graphql(graphqlOperation(mutations.updateClient, { input: client }));

    props.onUpdate(client); // Send update client deatils to parent component

    // Update auth if authentication changed
    if (client.url !== props.currentClient.url || client.password !== props.currentClient.password) {
      try {
        // Delete auth
        var account = {
          username: props.currentClient.url,
        };
        await API.graphql(graphqlOperation(mutations.deleteAccount, { input: account }));

        // Add auth
        var newAccount = {
          username: client.url,
          password: bcrypt.hashSync(client.password, 10),
          type: "client",
        };
        API.graphql(graphqlOperation(mutations.createAccount, { input: newAccount }));
      } catch (err) {
        console.log(`Update client auth error: ${err}`);
        setLoading(false);
      }
    }

    setLoading(false);
    setModalStatus(false);
  }

  return (
    <div style={{ display: "inline-block" }}>
      <Tooltip title="Edit" placement="top" arrow>
        <IconButton color="default" style={{ marginLeft: 15 }} aria-label="Edit" onClick={() => openEditModal()}>
          <SettingsIcon />
        </IconButton>
      </Tooltip>

      <Dialog open={modalStatus} onClose={() => setModalStatus(false)} maxWidth={"md"} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <Typography component="h4" variant="subtitle1" color="inherit">
            Edit Client: {props.currentClient.name}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box width="65%" display="inline-block" style={{ verticalAlign: "top" }}>
            <Typography variant="subtitle1" color="inherit">
              Client Info
            </Typography>
            <TextField
              name="clientName"
              defaultValue={props.currentClient.name}
              label="Company Name"
              type="string"
              fullWidth
              onChange={(e) => {
                e.persist();
                updateClientDetails({ type: "updateClientDetails", e });
              }}
            />
            <Box width="47%" display="inline-block" style={{ marginRight: "3%" }}>
              <TextField
                style={{ width: "100%", marginTop: 10 }}
                label="Annual Capital Expenditure"
                name="annualCapitalExpenditure"
                value={numberWithCommas(clientDetails.annualCapitalExpenditure)}
                onChange={(e) => {
                  e.persist();
                  e.target.value = numberWithoutCharacters(e.target.value);
                  updateClientDetails({ type: "updateClientDetails", e });
                }}
              />
              <TextField
                style={{ width: "100%", marginTop: 10 }}
                label="% CapEx Funded by C&I"
                name="capExFundedByCi"
                value={numberWithCommas(clientDetails.capExFundedByCi)}
                onChange={(e) => {
                  e.persist();
                  e.target.value = numberWithoutCharacters(e.target.value);
                  updateClientDetails({ type: "updateClientDetails", e });
                }}
              />
              <TextField
                style={{ width: "100%", marginTop: 10 }}
                label="Current C&I Portfolio"
                name="currentCiPortfolio"
                value={numberWithCommas(clientDetails.currentCiPortfolio)}
                onChange={(e) => {
                  e.persist();
                  e.target.value = numberWithoutCharacters(e.target.value);
                  updateClientDetails({ type: "updateClientDetails", e });
                }}
              />
            </Box>
            <Box width="47%" display="inline-block" style={{ marginLeft: "3%", verticalAlign: "top" }}>
              <TextField
                style={{ width: "100%", marginTop: 10 }}
                label="Annual License Fee"
                name="annualLicenseFee"
                value={numberWithCommas(clientDetails.annualLicenseFee)}
                onChange={(e) => {
                  e.persist();
                  e.target.value = numberWithoutCharacters(e.target.value);
                  updateClientDetails({ type: "updateClientDetails", e });
                }}
              />
              <TextField
                style={{ width: "100%", marginTop: 10 }}
                label="Setup Fee"
                name="setupFee"
                value={numberWithCommas(clientDetails.setupFee)}
                onChange={(e) => {
                  e.persist();
                  e.target.value = numberWithoutCharacters(e.target.value);
                  updateClientDetails({ type: "updateClientDetails", e });
                }}
              />
              <TextField
                style={{ width: "100%", marginTop: 10 }}
                label="Average Months to Monetization"
                name="averageMonthsToMonetization"
                value={numberWithCommas(clientDetails.averageMonthsToMonetization)}
                onChange={(e) => {
                  e.persist();
                  e.target.value = numberWithoutCharacters(e.target.value);
                  updateClientDetails({ type: "updateClientDetails", e });
                }}
              />
              <TextField
                style={{ width: "100%", marginTop: 10 }}
                label="Average Months to Upload C&I Portfolio"
                name="averageMonthsToUploadCiPortfolio"
                value={numberWithCommas(clientDetails.averageMonthsToUploadCiPortfolio)}
                onChange={(e) => {
                  e.persist();
                  e.target.value = numberWithoutCharacters(e.target.value);
                  updateClientDetails({ type: "updateClientDetails", e });
                }}
              />
            </Box>
          </Box>
          <Box width="30%" display="inline-block" align="center" style={{ marginTop: "8%", marginLeft: "5%" }}>
            <ImagePicker setImageFile={setNewLogoFile} previewImage={props.currentClient.logoImgFullPath} />
          </Box>

          <Box width="65%" display="inline-block" style={{ verticalAlign: "top" }}>
            <Typography variant="subtitle1" color="inherit" style={{ marginTop: 30 }}>
              Contact Info
            </Typography>
            <Box width="47%" display="inline-block" style={{ marginRight: "3%" }}>
              <TextField
                defaultValue={props.currentClient.contactName}
                style={{ width: "100%" }}
                label="Full Name"
                name="contactName"
                onChange={(e) => {
                  e.persist();
                  updateClientDetails({ type: "updateClientDetails", e });
                }}
              />
            </Box>
            <Box width="47%" display="inline-block" style={{ marginLeft: "3%", verticalAlign: "top" }}>
              <TextField
                defaultValue={props.currentClient.contactEmail}
                style={{ width: "100%" }}
                label="Email"
                name="contactEmail"
                onChange={(e) => {
                  e.persist();
                  updateClientDetails({ type: "updateClientDetails", e });
                }}
              />
            </Box>
          </Box>

          <Typography variant="subtitle1" color="inherit" style={{ marginTop: 18 }}>
            Client Access Page
          </Typography>
          <Box width="50%" display="inline-block">
            <TextField
              name="clientPassword"
              defaultValue={props.currentClient.password}
              label="Client Password"
              type="string"
              fullWidth
              onChange={(e) => {
                e.persist();
                updateClientDetails({ type: "updateClientDetails", e });
              }}
            />
          </Box>
          <Box width="48%" display="inline-block" style={{ float: "right" }}>
            <Typography
              variant="body1"
              color="inherit"
              style={{ width: "50%", marginTop: 18, paddingRight: 3, color: "gray", display: "inline-block", textAlign: "right" }}
            >
              https://roi.getincentify.com/
            </Typography>
            <TextField
              style={{ width: "49%" }}
              name="clientUrl"
              defaultValue={props.currentClient.url}
              label="Client Username *"
              type="string"
              fullWidth
              onChange={(e) => {
                e.persist();
                updateClientDetails({ type: "updateClientDetails", e });
              }}
            />
          </Box>
          <Typography color="inherit" style={{ fontSize: 14, marginTop: 18 }}>
            * Client Username - Please ONLY use letters, numbers and dashes(-) or underscores (_) - do NOT use spaces! Also make sure to NOT
            reuse the "Client Username" for two clients or it will not work! If this customer was "Coca Cola" then you would enter something
            like "coca-cola" or "cocacola" as the Client Username so that the system builds their login page at
            "https://roi.getincentify.com/coca-cola".
          </Typography>

          <Box>
            <Typography variant="subtitle1" color="inherit" style={{ marginTop: 30 }}>
              Calculator Fields
            </Typography>
            <Typography color="inherit" style={{ fontSize: 14, marginTop: 10 }}>
              We recommend keeping all 4 benefits "on", but if a particular benefit does not apply to this client then you can turn that
              benefit off so that it does not show up in this client's calculator and report.
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={clientDetails.bIncentifyDiscoveryImprovement}
                  name="bIncentifyDiscoveryImprovement"
                  color="primary"
                  onChange={(e) => {
                    e.persist();
                    updateClientDetails({ type: "updateBenefitConfig", e });
                  }}
                />
              }
              label="Incentify Discovery Improvement"
              style={{ width: "48%", marginRight: "2%", marginTop: 10 }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={clientDetails.bReduceNoncompliance}
                  name="bReduceNoncompliance"
                  color="primary"
                  onChange={(e) => {
                    e.persist();
                    updateClientDetails({ type: "updateBenefitConfig", e });
                  }}
                />
              }
              label="Reduce Noncompliance(claw back and recapture)"
              style={{
                width: "48%",
                marginLeft: "2%",
                marginRight: 0,
                marginTop: 10,
              }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={clientDetails.bMaximizeMonetization}
                  name="bMaximizeMonetization"
                  color="primary"
                  onChange={(e) => {
                    e.persist();
                    updateClientDetails({ type: "updateBenefitConfig", e });
                  }}
                />
              }
              label="Maximize Monetization(value and timing)"
              style={{ width: "48%", marginRight: "2%", marginTop: 10 }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={clientDetails.bIncreaseInternalEfficiencies}
                  name="bIncreaseInternalEfficiencies"
                  color="primary"
                  onChange={(e) => {
                    e.persist();
                    updateClientDetails({ type: "updateBenefitConfig", e });
                  }}
                />
              }
              label="Increase Internal Efficiencies"
              style={{
                width: "48%",
                marginLeft: "2%",
                marginRight: 0,
                marginTop: 10,
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions className="p15">
          <Button variant="contained" onClick={() => setModalStatus(false)} color="default" className="textTransformNone">
            Cancel
          </Button>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              className="colorButton2 textTransformNone"
              disabled={loading}
              onClick={() => handleSubmit(props)}
              style={{ width: 80 }}
            >
              Save
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
