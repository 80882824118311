import React from "react";
import { Switch } from "react-router-dom";
import AppliedRoute from "./components/appliedRoute";
import Home from "./views/home";
import SignIn from "./views/signIn";
import Report from "./views/report";

export default function Routes({ appProps }) {
  return (
    <Switch>
      <AppliedRoute path="/" exact component={Home} appProps={appProps} />
      <AppliedRoute
        path="/signin"
        exact
        component={SignIn}
        appProps={appProps}
      />
      <AppliedRoute
        path="/:url"
        exact
        component={Report}
        appProps={appProps}
      />
    </Switch>
  );
}
