import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import AppNavBar from "../components/appNavBar";
import PageHeader from "../components/pageHeader";
import ChartEstimatedBenefit from "../components/chartEstimatedBenefit";
import ChartEcoBenefit from "../components/chartEcoBenefit";
import ReportInputs from "../components/reportInputs";
import Roi from "../components/roi";
import BenefitAssumptions from "../components/benefitAssumptions";
import SignIn from "./signIn";
import { s3Get } from "../libs/awsS3Lib";
import { numberWithCommas } from "../libs/utils";

const useStyles = makeStyles(theme => ({
  content: {
    marginLeft: 60,
    transition: "all ease .3s",
    paddingTop: "16px",
    paddingBottom: "40px",
    backgroundColor: "#F1F1F1"
  },
  container: {
    padding: 0,
    marginBottom: theme.spacing(2),
    background: "white"
  },
  pageBody: {
    padding: theme.spacing(5),
    border: "1px solid #d2d1d1"
  },
  paper: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    boxShadow: "none",
    borderRadius: 0
  },
  summaryBox: {
    marginBottom: 30
  },
  chartBox: {
    marginBottom: 20
  },
  oneOfThreeBox: {
    paddingRight: "10px"
  },
  twoOfThreeBox: {
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  threeOfThreeBox: {
    paddingLeft: "10px"
  },
  fixedHeight: {
    height: 400
  },
  fixedChartHeight: {
    height: 400
  },
  subtitle1: {
    fontWeight: "bold"
  },
  pb0: {
    paddingBottom: "0px !important"
  },
  mt30: {
    marginTop: "30px"
  },
  fontWeightBold: {
    fontWeight: "bold"
  }
}));

function Report(props) {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const fixedHeightChart = clsx(classes.paper, classes.fixedChartHeight);

  const [isLoading, setLoading] = useState(true);
  const [adjustmentBoxStatus, setAdjustmentBoxStatus] = useState(false);
  const [client, setClientInfo] = useState(null);
  const [calcData, setCalcData] = useState({
    currentCiPortfolio: 0,
    targetCiPortfolio: 0,
    creditIncentiveDiscovery: 0,
    incentifyDiscoveryImprovement: 0,
    reduceNoncompliance: 0,
    maximizeMonetization: 0,
    increaseInternalEfficiencies: 0,
    incentifyDiscoveryImprovementAmt: 0,
    totalIncentifyDiscoveryImprovementAmt: 0,
    totalGrossBenefit: 0,
    reduceNoncomplianceAmt: 0,
    maximizeMonetizationAmt: 0,
    increaseInternalEfficienciesAmt: 0,
    ciOfPortfolioBenefitMonthly: 0,
    monetizationByYear: 0,
    monthlySubscriptionFee: [],
    monthlyCiDiscovery: [],
    monthlyCiDiscoveryAmt: [],
    monthlyCiPortfolioAdded: [],
    monthlyCiPortfolioAddedAmt: [],
    monthlyCumulativeCiOnIncentify: [],
    monthlyPortfolioSizeBenefits: [],
    monthlyIncentifyDiscoveryImprovement: [],
    totalMonthlyIncentifyBenefit: [],
    netCumulativeEconomicBenefit: [],
    twoYearBenefitTotalAmt: 0,
    fourYearBenefitTotalAmt: 0,
    firstYearRoi: 0,
    secondYearRoi: 0,
    thirdYearRoi: 0,
    fourthYearRoi: 0,
    twoYearRoi: 0,
    fourYearRoi: 0,
    projectBreakevenMonth: 0
  });

  const clientUrl = props.match.params.url;
  const sessUser = localStorage.getItem("user");


  useEffect(() => {
    getClientInfo();
  }, []);

  useEffect(() => {
    updateCalcData();
  }, [client]);

  async function getClientInfo() {
    try {
      const data = await API.graphql(graphqlOperation(queries.listClients));

      data.data.listClients.items.forEach(async function(client) {
        if (client.url === clientUrl) {
          client.logoImgFullPath = client.logoImg ? await s3Get(client.logoImg) : null;
          setClientInfo(client);
          setLoading(false);
        }
      });
    } catch (err) {
      console.log(`Error: ${err}`);
    }
  }

  function updateClient(calcPrimaryFields) {
    setClientInfo({
      ...client,
      currentCiPortfolio: calcPrimaryFields.currentCiPortfolio,
      incentifyDiscoveryImprovement: calcPrimaryFields.incentifyDiscoveryImprovement,
      reduceNoncompliance: calcPrimaryFields.reduceNoncompliance,
      maximizeMonetization: calcPrimaryFields.maximizeMonetization,
      increaseInternalEfficiencies: calcPrimaryFields.increaseInternalEfficiencies
    });

    var updatedClient = {
      id: client.id,
      currentCiPortfolio: calcPrimaryFields.currentCiPortfolio,
      incentifyDiscoveryImprovement: calcPrimaryFields.incentifyDiscoveryImprovement,
      reduceNoncompliance: calcPrimaryFields.reduceNoncompliance,
      maximizeMonetization: calcPrimaryFields.maximizeMonetization,
      increaseInternalEfficiencies: calcPrimaryFields.increaseInternalEfficiencies
    };
    API.graphql(graphqlOperation(mutations.updateClient, { input: updatedClient }));
  }

  function updateCalcData(calcPrimaryFields) {
    if (!client) return;

    var currentCiPortfolio = calcPrimaryFields ? calcPrimaryFields.currentCiPortfolio : client.currentCiPortfolio;
    var incentifyDiscoveryImprovement = calcPrimaryFields ? calcPrimaryFields.incentifyDiscoveryImprovement : client.incentifyDiscoveryImprovement;
    var reduceNoncompliance = calcPrimaryFields ? calcPrimaryFields.reduceNoncompliance : client.reduceNoncompliance;
    var maximizeMonetization = calcPrimaryFields ? calcPrimaryFields.maximizeMonetization : client.maximizeMonetization;
    var increaseInternalEfficiencies = calcPrimaryFields ? calcPrimaryFields.increaseInternalEfficiencies : client.increaseInternalEfficiencies;
    var grossBenefitLessDiscovery = reduceNoncompliance + maximizeMonetization + increaseInternalEfficiencies;
    
    // Report Inputs
    var targetCiPortfolio = client.annualCapitalExpenditure * client.capExFundedByCi / 100;
    var creditIncentiveDiscovery = Math.max(client.annualCapitalExpenditure * client.capExFundedByCi / 100 - currentCiPortfolio, 0);

    // Benefit Assumptions
    var incentifyDiscoveryImprovementAmt = creditIncentiveDiscovery * incentifyDiscoveryImprovement / 100;

    // Monthly Data
    var ciOfPortfolioBenefitMonthly = 0;
    var monetizationByYear = 12 / client.averageMonthsToMonetization;
    ciOfPortfolioBenefitMonthly += client.bReduceNoncompliance ? reduceNoncompliance : 0;
    ciOfPortfolioBenefitMonthly += client.bMaximizeMonetization ? maximizeMonetization : 0;
    ciOfPortfolioBenefitMonthly += client.bIncreaseInternalEfficiencies ? increaseInternalEfficiencies : 0;
    ciOfPortfolioBenefitMonthly = ciOfPortfolioBenefitMonthly / 12;

    // Monthly Subscription Fee  [0]: Month 1, [1]: Month 2, ... [47]: Month 48
    var monthlySubscriptionFee = [];
    for (var i = 0; i < 48; i++) {
      if (i === 0) {
        monthlySubscriptionFee[i] = -client.annualLicenseFee - client.setupFee;
      } else if (i === 12 || i === 24 || i === 36) {
        monthlySubscriptionFee[i] = -client.annualLicenseFee;
      } else {
        monthlySubscriptionFee[i] = 0;
      }
    }

    // Monthly C&I Discovery  [0]: Month 1, [1]: Month 2, ... [47]: Month 48
    var monthlyCiDiscovery = [];      // (%)
    var monthlyCiDiscoveryAmt = [];   // ($)
    var totalIncentifyDiscoveryImprovementAmt = 0;   // Incentify Discovery Improvement $ for Waterfall Chart
    for (i = 0; i < 48; i++) {
      if (i < 12) {   // Don't calculate for first year
        continue;
      }
      if (i === 23 || i === 35 || i === 47) {
        monthlyCiDiscovery[i] = Math.min(monetizationByYear * (i + 1 - 12) / 12 * 100, 100);
        monthlyCiDiscoveryAmt[i] = monthlyCiDiscovery[i] * incentifyDiscoveryImprovementAmt / 100;
      } else {
        monthlyCiDiscovery[i] = 0;
        monthlyCiDiscoveryAmt[i] = 0;
      }
      totalIncentifyDiscoveryImprovementAmt += monthlyCiDiscoveryAmt[i];
    }

    // Monthly C&I Portfolio Added  [0]: Month 1, [1]: Month 2, ... [47]: Month 48
    var monthlyCiPortfolioAdded = [];      // (%)
    var monthlyCiPortfolioAddedAmt = [];   // ($)
    for (i = 0; i < 48; i++) {
      if (i < 3) {    // Don't calculate for first 3 months
        continue;
      }
      if ((i + 1 - 3) <= client.averageMonthsToUploadCiPortfolio) {
        monthlyCiPortfolioAdded[i] = 1 / client.averageMonthsToUploadCiPortfolio * 100;
        monthlyCiPortfolioAddedAmt[i] = monthlyCiPortfolioAdded[i] * currentCiPortfolio / 100;
      } else {
        monthlyCiPortfolioAdded[i] = 0;
        monthlyCiPortfolioAddedAmt[i] = 0;
      }
    }

    // Monthly Cumulative C&I on Incentify  [0]: Month 1, [1]: Month 2, ... [47]: Month 48
    var monthlyCumulativeCiOnIncentify = [];
    monthlyCumulativeCiOnIncentify[0] = 0;
    for (i = 1; i < 48; i++) {
      monthlyCumulativeCiOnIncentify[i] = monthlyCiPortfolioAddedAmt[i] ? monthlyCumulativeCiOnIncentify[i - 1] + monthlyCiPortfolioAddedAmt[i] : monthlyCumulativeCiOnIncentify[i - 1];
    }

    var monthlyPortfolioSizeBenefits = [];            // Monthly Portfolio Size Benefits
    var monthlyIncentifyDiscoveryImprovement = [];    // Monthly Incentify Discovery Improvement
    var totalMonthlyIncentifyBenefit = [];            // Total Monthly Incentify Benefit 
    var cumulativeCiPortfolioAddedAmt = 0;
    var totalGrossBenefit = 0;                        // Total Gross Benefit for Waterfall Chart
    var grossBenefitLessDiscoveryAmt = 0;                // Gross Benefit Less Discovery for Waterfall Chart
    for (i = 0; i < 48; i++) {
      if (i >= 6) {      // Don't calculate for first 6 months
        cumulativeCiPortfolioAddedAmt += monthlyCiPortfolioAddedAmt[i - 3];
        monthlyPortfolioSizeBenefits[i] = cumulativeCiPortfolioAddedAmt * ciOfPortfolioBenefitMonthly / 100;
      }
      monthlyIncentifyDiscoveryImprovement[i] = monthlyCiDiscoveryAmt[i] ? monthlyCiDiscoveryAmt[i] : 0;
      totalMonthlyIncentifyBenefit[i] = (monthlyPortfolioSizeBenefits[i] ? monthlyPortfolioSizeBenefits[i] : 0) + monthlyIncentifyDiscoveryImprovement[i];
      totalGrossBenefit += parseFloat(totalMonthlyIncentifyBenefit[i]);
    }
    grossBenefitLessDiscoveryAmt = totalGrossBenefit - totalIncentifyDiscoveryImprovementAmt;

    var reduceNoncomplianceAmt = grossBenefitLessDiscoveryAmt * (reduceNoncompliance / grossBenefitLessDiscovery);
    var maximizeMonetizationAmt = grossBenefitLessDiscoveryAmt * (maximizeMonetization / grossBenefitLessDiscovery);
    var increaseInternalEfficienciesAmt = grossBenefitLessDiscoveryAmt * (increaseInternalEfficiencies / grossBenefitLessDiscovery);

    var grossMonthlyEconomicBenefit = [];               // Gross Monthly Economic Benefit
    var netMonthlyEconomicBenefit = [];                 // Net Monthly Economic Benefit
    var netCumulativeEconomicBenefit = [];              // Net Cumulative Economic Benefit
    for (i = 0; i < 48; i++) {
      grossMonthlyEconomicBenefit[i] = totalMonthlyIncentifyBenefit[i];
      netMonthlyEconomicBenefit[i] = monthlySubscriptionFee[i] + grossMonthlyEconomicBenefit[i];
      if (i === 0) {
        netCumulativeEconomicBenefit[i] = netMonthlyEconomicBenefit[i];
      } else {
        netCumulativeEconomicBenefit[i] = netCumulativeEconomicBenefit[i - 1] + netMonthlyEconomicBenefit[i];
      }
    }

    var firstYearBenefitTotalAmt = netCumulativeEconomicBenefit[11];
    var twoYearBenefitTotalAmt = netCumulativeEconomicBenefit[23];
    var threeYearBenefitTotalAmt = netCumulativeEconomicBenefit[35];
    var fourYearBenefitTotalAmt = netCumulativeEconomicBenefit[47];
    var secondYearBenefitTotalAmt = twoYearBenefitTotalAmt - firstYearBenefitTotalAmt;
    var thirdYearBenefitTotalAmt = threeYearBenefitTotalAmt - twoYearBenefitTotalAmt;
    var fourthYearBenefitTotalAmt = fourYearBenefitTotalAmt - threeYearBenefitTotalAmt;
    var projectBreakevenMonth = 0;

    for (i = 0; i < 48; i++) {
      if (projectBreakevenMonth === 0 && netCumulativeEconomicBenefit[i] > 0) {
        projectBreakevenMonth = i + 1;
      }
      netCumulativeEconomicBenefit[i] = parseFloat(netCumulativeEconomicBenefit[i].toFixed(0));
    }

    setCalcData({
      ...calcData,
      currentCiPortfolio: currentCiPortfolio,
      targetCiPortfolio: targetCiPortfolio,
      creditIncentiveDiscovery: creditIncentiveDiscovery,
      incentifyDiscoveryImprovement: incentifyDiscoveryImprovement.toFixed(2),
      reduceNoncompliance: reduceNoncompliance.toFixed(2),
      maximizeMonetization: maximizeMonetization.toFixed(2),
      increaseInternalEfficiencies: increaseInternalEfficiencies.toFixed(2),
      incentifyDiscoveryImprovementAmt: incentifyDiscoveryImprovementAmt.toFixed(0),
      totalIncentifyDiscoveryImprovementAmt: totalIncentifyDiscoveryImprovementAmt,
      totalGrossBenefit: totalGrossBenefit,
      reduceNoncomplianceAmt: reduceNoncomplianceAmt.toFixed(0),
      maximizeMonetizationAmt: maximizeMonetizationAmt.toFixed(0),
      increaseInternalEfficienciesAmt: increaseInternalEfficienciesAmt.toFixed(0),
      ciOfPortfolioBenefitMonthly: ciOfPortfolioBenefitMonthly,
      monetizationByYear: monetizationByYear,
      monthlySubscriptionFee: monthlySubscriptionFee,
      monthlyCiDiscovery: monthlyCiDiscovery,
      monthlyCiDiscoveryAmt: monthlyCiDiscoveryAmt,
      monthlyCiPortfolioAdded: monthlyCiPortfolioAdded,
      monthlyCiPortfolioAddedAmt: monthlyCiPortfolioAddedAmt,
      monthlyCumulativeCiOnIncentify: monthlyCumulativeCiOnIncentify,
      monthlyPortfolioSizeBenefits: monthlyPortfolioSizeBenefits,
      monthlyIncentifyDiscoveryImprovement: monthlyIncentifyDiscoveryImprovement,
      totalMonthlyIncentifyBenefit: totalMonthlyIncentifyBenefit,
      netCumulativeEconomicBenefit: netCumulativeEconomicBenefit,
      twoYearBenefitTotalAmt: twoYearBenefitTotalAmt.toFixed(0),
      fourYearBenefitTotalAmt: fourYearBenefitTotalAmt.toFixed(0),
      firstYearRoi: parseFloat((firstYearBenefitTotalAmt / (client.annualLicenseFee + client.setupFee)) * 100).toFixed(0),
      secondYearRoi: parseFloat((secondYearBenefitTotalAmt / (client.annualLicenseFee)) * 100).toFixed(0),
      thirdYearRoi: parseFloat((thirdYearBenefitTotalAmt / (client.annualLicenseFee)) * 100).toFixed(0),
      fourthYearRoi: parseFloat((fourthYearBenefitTotalAmt / (client.annualLicenseFee)) * 100).toFixed(0),
      twoYearRoi: parseFloat((twoYearBenefitTotalAmt / ((client.annualLicenseFee * 2) + client.setupFee)) * 100).toFixed(0),
      fourYearRoi: parseFloat((fourYearBenefitTotalAmt / ((client.annualLicenseFee * 4) + client.setupFee)) * 100).toFixed(0),
      projectBreakevenMonth: projectBreakevenMonth
    });
  }

  async function signOut() {
    localStorage.clear();
    props.userHasAuthenticated(false);
    if (sessUser === "admin") {
      props.history.push(`/signin`);
    } else {
      props.history.push(`/${clientUrl}`);
    }
  }

  var contentLeft = adjustmentBoxStatus ? 300 : 60;

  return !props.isAuthenticated || (sessUser !== clientUrl && sessUser !== "admin") ? (
    <SignIn userHasAuthenticated={props.userHasAuthenticated} history={props.history} clientUrl={clientUrl} />
  ) : (
    !isLoading && (
      <div className={classes.root}>
        <CssBaseline />
        <AppNavBar
          signOut={signOut}
          pageType="report"
          adjustmentBoxStatus={adjustmentBoxStatus}
          setAdjustmentBoxStatus={setAdjustmentBoxStatus}
          client={client}
          updateClient={updateClient}
          updateCalcData={updateCalcData}
        />
        <main className={classes.content} style={{ marginLeft: contentLeft }}>
          <Container maxWidth="lg" className={classes.container}>
            <PageHeader client={client} />
            <Grid container className={classes.pageBody}>
              <Grid item xs={12} className={classes.summaryBox}>
                <Typography variant="subtitle1" className={classes.subtitle1} gutterBottom>
                  Summary
                </Typography>
                <Typography variant="body1" gutterBottom>
                  With a C&I portfolio {" "}
                  <Typography variant="body1" component="span" className={classes.fontWeightBold}>
                    ${numberWithCommas(calcData.currentCiPortfolio)}
                  </Typography> {" "}
                   and annual capital expenditures of {" "}
                  <Typography variant="body1" component="span" className={classes.fontWeightBold}>
                    ${numberWithCommas(client.annualCapitalExpenditure)}
                  </Typography> {" "}
                  the estimated gross benefit over 4 years would be {" "}
                  <Typography variant="body1" component="span" className={classes.fontWeightBold}>
                    ${numberWithCommas(calcData.totalGrossBenefit)}
                  </Typography>
                  . To be more precise and calculate the realistic net benefit, we made an assumption on the prospective {client.name} implementation based on our observations for the average client implementation process:
                  <Grid style={{paddingLeft:20, paddingTop:10, paddingBottom:10}}>
                    * The benefit categories for C&I portfolio would commence 4 months subsequent to the month in which it was uploaded.
                  </Grid>
                  The estimated gross benefit is based on a portion of incentive discovery due to Incentify discovery improvements as a percentage of annual capital expenditures and three additional benefit categories as a percentage of the overall C&I portfolio. Again, the above assumption is the average we have observed and while your case may be different, we want to create a realistic picture for you. If these averages hold true for {client.name}, then your net economic benefit over the first two years would be {" "}
                  <Typography variant="body1" component="span" className={classes.fontWeightBold}>
                    ${numberWithCommas(calcData.twoYearBenefitTotalAmt)}
                  </Typography>{" "}
                  with a projected breakeven relative to the license and setup fee in your {" "}
                  <Typography variant="body1" component="span" className={classes.fontWeightBold}>
                    {calcData.projectBreakevenMonth}th
                  </Typography>{" "}
                  month based on {" "}
                  <Typography variant="body1" component="span" className={classes.fontWeightBold}>
                    {client.averageMonthsToMonetization}
                  </Typography>{" "}
                  months to monetization for new C&I and {" "}
                  <Typography variant="body1" component="span" className={classes.fontWeightBold}>
                    {client.averageMonthsToUploadCiPortfolio}
                  </Typography>{" "}
                  months to upload your C&I Portfolio from onboarding completion date.
                </Typography>
              </Grid>

              <Grid item xs={12} className={classes.chartBox}>
                <Typography variant="subtitle1" className={classes.subtitle1} gutterBottom>
                  Breakdown of Estimated Benefits
                </Typography>
                <Paper className={fixedHeightChart}>
                  <ChartEstimatedBenefit client={client} calcData={calcData} />
                </Paper>
              </Grid>

              <Grid item xs={12} className={classes.pb0}>
                <Typography variant="subtitle1" className={classes.subtitle1} gutterBottom>
                  Impact Details
                </Typography>
              </Grid>
              {/* Report Inputs */}
              <Grid item xs={12} md={4} className={classes.oneOfThreeBox}>
                <Paper className={fixedHeightPaper}>
                  <ReportInputs client={client} calcData={calcData} />
                </Paper>
              </Grid>
              {/* Benefit Assumptions */}
              <Grid item xs={12} md={4} className={classes.twoOfThreeBox}>
                <Paper className={fixedHeightPaper}>
                  <BenefitAssumptions client={client} calcData={calcData} />
                </Paper>
              </Grid>
              {/* ROI */}
              <Grid item xs={12} md={4} className={classes.threeOfThreeBox}>
                <Paper className={fixedHeightPaper}>
                  <Roi client={client} calcData={calcData} />
                </Paper>
              </Grid>

              {/* Economic Benefit Chart */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" className={classes.subtitle1} gutterBottom>
                  Net Cumulative Economic Benefit
                </Typography>
                <Paper className={fixedHeightChart}>
                  <ChartEcoBenefit calcData={calcData} />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </main>
      </div>
    )
  );
}

export default Report;
