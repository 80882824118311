import React, { useState, useReducer } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import bcrypt from "bcryptjs";
import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const theme = createMuiTheme({
  typography: {
    h3: {
      fontSize: 24,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[800],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  incentifyLogo: {
    width: "300px",
    marginBottom: "30px",
  },
}));

const initialFormState = {
  username: "",
  password: "",
};

function reducer(state, action) {
  switch (action.type) {
    case "updateFormState":
      return {
        ...state,
        [action.e.target.name]: action.e.target.value,
      };
    default:
      return state;
  }
}

export default function Form(props) {
  const [formType, updateFormType] = useState("signIn");
  const [formState, updateFormState] = useReducer(reducer, initialFormState);
  const [loading, setLoading] = React.useState(false);

  async function signIn({ username, password }, updateFormType, props) {
    try {
      setLoading(true);

      if (props.clientUrl !== undefined) {
        username = props.clientUrl;
      }

      const account = await API.graphql(
        graphqlOperation(queries.getAccount, {
          username: username,
        })
      );

      if (!account.data.getAccount || !bcrypt.compareSync(password, account.data.getAccount.password)) {
        alert("Username or password is wrong. Please try again.");
        setLoading(false);
        return;
      }

      props.userHasAuthenticated(true);
      localStorage.setItem("user", account.data.getAccount.username);
      setLoading(false);

      if (props.clientUrl === undefined) {
        props.history.push("/");
      } else {
        props.history.push("/" + props.clientUrl);
      }
    } catch (err) {
      console.log("Error signing in..", err);
      setLoading(false);
      alert(err.message);
    }
  }

  function renderForm() {
    switch (formType) {
      case "signIn":
        return (
          <SignIn
            signIn={() => signIn(formState, updateFormType, props)}
            updateFormState={(e) => updateFormState({ type: "updateFormState", e })}
            clientUrl={props.clientUrl}
            loading={loading}
          />
        );
      default:
        return null;
    }
  }

  return (
    <div>
      <div>{renderForm(formState)}</div>
    </div>
  );
}

function SignIn(props) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div style={styles.container}>
        <img
          src="https://oix-static-assets.s3.amazonaws.com/images/logo/v1/incentify-logo-on-white.png"
          className={classes.incentifyLogo}
          alt="logo"
        />
        <Typography variant="h3" style={styles.title}>
          ROI Calculator
        </Typography>

        {props.clientUrl === undefined && (
          <input
            name="username"
            onChange={(e) => {
              e.persist();
              props.updateFormState(e);
            }}
            style={styles.input}
            placeholder="Username"
          />
        )}
        <input
          type="password"
          name="password"
          onChange={(e) => {
            e.persist();
            props.updateFormState(e);
          }}
          onKeyPress={(e) => {
            var code = e.keyCode || e.which;
            if (code === 13) {
              props.signIn();
            }
          }}
          style={styles.input}
          placeholder="Password"
        />
        <div className={classes.wrapper} style={{ marginTop: 50 }}>
          <Button
            variant="contained"
            color="primary"
            className="textTransformNone"
            style={styles.button}
            disabled={props.loading}
            onClick={props.signIn}
          >
            Sign In
          </Button>
          {props.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </div>
    </ThemeProvider>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: 150,
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    marginBottom: 20,
  },
  input: {
    height: 45,
    marginTop: 8,
    width: 300,
    maxWidth: 300,
    padding: "0px 8px",
    fontSize: 16,
    outline: "none",
    border: "none",
    borderBottom: "2px solid rgba(0, 0, 0, .3)",
  },
  button: {
    color: "white",
    width: 316,
    height: 45,
    fontWeight: "600",
    fontSize: 14,
    cursor: "pointer",
    border: "none",
    outline: "none",
    borderRadius: 3,
    boxShadow: "0px 1px 3px rgba(0, 0, 0, .3)",
  },
};
