import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { numberWithCommas } from "../libs/utils";

const useStyles = makeStyles(theme => ({
  subtitle: {
    marginLeft: 16
  }
}));

export default function ReportInputs(props) {
  const classes = useStyles();

  // Generate table row
  function createData(id, item, amount) {
    return { id, item, amount };
  }

  const rows = [
    createData(0, "Annual Capital Expenditure", "$" + numberWithCommas(props.client.annualCapitalExpenditure)),
    createData(1, "% CapEx Funded by C&I", numberWithCommas(props.client.capExFundedByCi, 1) + "%"),
    createData(2, "Target C&I Portfolio", "$" + numberWithCommas(props.calcData.targetCiPortfolio)),
    createData(3, "Current C&I Portfolio", "$" + numberWithCommas(props.calcData.currentCiPortfolio)),
    createData(4, "Credit & Incentive Discovery", "$" + numberWithCommas(props.calcData.creditIncentiveDiscovery)),
    createData(5, "Annual License Fee", "$" + numberWithCommas(props.client.annualLicenseFee)),
    createData(6, "Setup Fee", "$" + numberWithCommas(props.client.setupFee)),
    createData(7, "Avg Months to Monetization", props.client.averageMonthsToMonetization),
    createData(8, "Avg Months to Upload C&I Portfolio", props.client.averageMonthsToUploadCiPortfolio)
  ];

  return (
    <React.Fragment>
      <Typography variant="subtitle1" gutterBottom className={classes.subtitle}>
        Report Inputs
      </Typography>
      <Table size="small">
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.item}</TableCell>
              <TableCell align="right">{row.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
