import React, { useState, useEffect } from "react";
import Routes from "./Routes";

export default function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  function onLoad() {
    var currentUser = localStorage.getItem("user");

    if (currentUser) {
      userHasAuthenticated(true);
    }
    setIsAuthenticating(false);
  }

  return (
    !isAuthenticating && (
      <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
    )
  );
}
