/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      name
      url
      password
      logoImg
      contactName
      contactEmail
      annualCapitalExpenditure
      capExFundedByCi
      currentCiPortfolio
      annualLicenseFee
      setupFee
      averageMonthsToMonetization
      averageMonthsToUploadCiPortfolio
      bIncentifyDiscoveryImprovement
      bReduceNoncompliance
      bMaximizeMonetization
      bIncreaseInternalEfficiencies
      incentifyDiscoveryImprovement
      reduceNoncompliance
      maximizeMonetization
      increaseInternalEfficiencies
      createdAt
      updatedAt
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        url
        password
        logoImg
        contactName
        contactEmail
        annualCapitalExpenditure
        capExFundedByCi
        currentCiPortfolio
        annualLicenseFee
        setupFee
        averageMonthsToMonetization
        averageMonthsToUploadCiPortfolio
        bIncentifyDiscoveryImprovement
        bReduceNoncompliance
        bMaximizeMonetization
        bIncreaseInternalEfficiencies
        incentifyDiscoveryImprovement
        reduceNoncompliance
        maximizeMonetization
        increaseInternalEfficiencies
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($username: String!) {
    getAccount(username: $username) {
      username
      password
      type
      createdAt
      updatedAt
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $username: String
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAccounts(
      username: $username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        password
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
